import cn from 'classnames';
import { HTMLComment } from 'src/components/HTMLComment';
import { VideoPlayer } from 'src/components/VideoPlayer';
import { Wrapper } from 'src/components/Wrapper';
import { consoleRedirectUrl } from 'src/global';
import { useFormatConsoleUrl } from 'src/hooks/useFormatConsoleUrl';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { Button } from 'src/uikit/Button';
import { Color } from 'src/uikit/Button/constants';

import s from './MainSlide.module.scss';

export type MainSlideProps = {
  id: string;
  title: string;
  description?: string;
  image?: {
    desktop: string;
    tablet?: string;
  };
  imageBg?: {
    desktopLg?: string;
    desktop: string;
    tablet: string;
    mobile: string;
  };
  button: {
    link: string;
    text: string;
    color?: Color;
    consoleUrl?: boolean;
  };

  theme?: string;
  videoBg?: {
    link: string;
    previewImage?: string;
  };

  classNameSlide?: string;
  classNameTitle?: string;
  classNameDescription?: string;
};

export function MainSlide({
  id,
  title,
  description,
  image,
  button,
  theme,
  videoBg,
  imageBg,
  classNameSlide,
  classNameTitle,
  classNameDescription,
}: MainSlideProps) {
  const {
    windowWidth,
    breakpoints: { SM, MD, XLG },
  } = useWindowWidth();

  const consoleLink = useFormatConsoleUrl(
    consoleRedirectUrl,
    'banner',
    button.text,
  );

  if (button.consoleUrl) {
    button.link = consoleLink;
  }
  return (
    <div
      className={cn(s.root, classNameSlide, {
        [s.withoutTheme]: videoBg || imageBg,
        [s.whiteText]: theme === '#222222',
      })}
      style={theme ? { backgroundColor: theme } : {}}
    >
      {imageBg && !videoBg && (
        <div className={s.backgroundWrapper}>
          <picture>
            <source srcSet={imageBg.mobile} media={`(max-width: ${SM}px)`} />
            <source srcSet={imageBg.tablet} media={`(max-width: ${MD}px)`} />
            {imageBg.desktopLg && imageBg.desktopLg ? (
              <>
                <source
                  srcSet={imageBg.desktop}
                  media={`(max-width: ${XLG}px)`}
                />
                <source srcSet={imageBg.desktopLg} />
              </>
            ) : (
              <source srcSet={imageBg.desktop} />
            )}
            <img
              loading="lazy"
              src={imageBg.desktop}
              className={s.backgroundImage}
              alt="background"
            />
          </picture>
        </div>
      )}

      {videoBg && !imageBg && (
        <>
          <HTMLComment text="noindex" />
          <VideoPlayer
            link={videoBg.link}
            previewImage={videoBg.previewImage}
            muted
            loop
            autoPlay
            className={s.videoBg}
            videoClassName={s.videoClassName}
          />
          <HTMLComment text="/noindex" />
        </>
      )}
      <Wrapper className={s.wrapper}>
        <div className={s.grid}>
          <div className={s.content}>
            <h1
              className={cn(s.title, classNameTitle)}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {description && (
              <div
                className={cn(s.description, classNameDescription)}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}

            <Button
              className={s.button}
              link={button.link}
              color={button.color || Button.colors.Yellow}
              analyticsOptions={{
                action: button.link,
                clickZone: 'body',
                clickElement: 'button',
                clickContent: button.text || 'подробнее',
                uniqueId: `main-slide-button-${id}`,
                transitionType: 'inside-link',
              }}
            >
              {button.text || 'подробнее'}
            </Button>
          </div>
          {image && !imageBg && windowWidth && windowWidth > SM && (
            <picture className={s.imageWrapper}>
              {image.tablet && (
                <source srcSet={image.tablet} media={`(max-width: ${MD}px)`} />
              )}
              <source srcSet={image.desktop} media={`(max-width: ${XLG}px)`} />
              <img
                loading="lazy"
                width={453}
                height={453}
                src={image.desktop}
                className={s.image}
                alt="main slide"
              />
            </picture>
          )}
        </div>
      </Wrapper>
    </div>
  );
}
