import cn from 'classnames';
import { Wrapper } from 'src/components/Wrapper';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { CarouselBase } from '../CarouselBase';
import { ClientCasesProps } from '../CarouselBase/slides/ClientCasesSlide';
import s from './ClientCasesCarousel.module.scss';

export interface ClientCasesCarouselProps {
  id?: string;
  slides: ClientCasesProps[];
  title?: string;
  className?: string;
}

export function ClientCasesCarousel({
  id,
  slides,
  title,
  className = '',
}: ClientCasesCarouselProps) {
  const {
    windowWidth,
    breakpoints: { CAROUSEL_NAVIGATION, MD },
  } = useWindowWidth();

  return (
    <section
      id={id}
      className={cn(s.root, className)}
      data-qa="client-cases-carousel"
    >
      {title && (
        <Wrapper>
          <h2 className={s.title}>{title}</h2>
        </Wrapper>
      )}
      <Wrapper className={s.carouselWrapper}>
        <CarouselBase
          autoPlay
          slidesCount={slides.length}
          withNavigation={!!windowWidth && windowWidth > CAROUSEL_NAVIGATION}
          alwaysShowPaginationDots
          loop
          paginationBulletClassName={s.bullet}
          carouselClassName={s.carousel}
          navigationClassName={s.navigation}
          slideClassName={s.slide}
          slidesPerView={windowWidth && windowWidth <= MD ? 1 : 2}
          slidesSpacing={32}
        >
          {slides.map(({ image, text, link }, index) => (
            <CarouselBase.ClientCases
              key={index}
              text={text}
              image={image}
              link={link}
            />
          ))}
        </CarouselBase>
      </Wrapper>
    </section>
  );
}
