import { ReviewProps } from 'src/components/Carousel/types';
import { RichText } from 'src/components/RichText';

import s from './Review.module.scss';

export function ReviewSlide({
  title,
  description,
  text,
}: Omit<ReviewProps, 'id'>) {
  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.author}>
          <h3 className={s.title} data-qa="slide_title">
            {title}
          </h3>
          {description && <RichText richText={description} />}
        </div>
        <RichText richText={text} />
      </div>
    </div>
  );
}
